/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

@import './utils/bootstrap.scss';

@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';

@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/progress';

@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/nav';

@import '../fonts/fontface.scss';

@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/utilities/api';
@import '../themes/index.scss';

img {
  max-width: 100%;
}

textarea {
  resize: none;
  &.form-control {
    min-height: 100px;
  }
}

.lcl {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  &-1 {
    -webkit-line-clamp: 1;
  }
}

.btn-success {
  color: $white !important;
}

.main-panel > .content {
  position: relative;
  float: right;
  width: calc(100% - 260px);
}

.w-248 {
  width: 248px;
  transition: all 0.4s ease;
}

.w-260 {
  width: 260px;
  transition: all 0.4s ease;
}

.w-35 {
  width: 35%;
}

.w-350 {
  width: 350px;
}

.w-400 {
  width: 400px;
  transition: all 0.4s ease;
}

.w-45 {
  width: 45px;
}

.w-450 {
  width: 450px;
}

.pd-t-80 {
  padding-top: 80px;
}

.h-35 {
  height: 35px;
}

.h-45 {
  height: 45px;
}

.h-80 {
  height: 80px;
}
.h-196 {
  height: 196px;
}
.img-avatar-35 {
  width: 35px;
}

.img-avatar {
  min-width: 45px;
  max-width: 45px;
}

.w-80 {
  width: 80px;
}

.w-98 {
  width: 98px;
}

.w-150 {
  width: 150px;
}

.w-110 {
  width: 110px;
}

.w-180 {
  width: 180px;
}

.w-196 {
  width: 196px;
}

.w-200 {
  width: 200px;
}

.mw-80 {
  min-width: 80px;
}

.mw-100 {
  min-width: 100px;
}

.h-110 {
  height: 110px;
}

.w-20 {
  width: 20px;
}

.w-55 {
  width: 55px;
}

.h-55 {
  height: 55px;
}

.bg-status-1 {
  background-color: $color-green-2;
  color: $green !important;
}

.bg-posted {
  background-color: $color-green-2;
  color: $green !important;
}

.bg-status-2 {
  background-color: rgba($color: $yellow, $alpha: 0.4);
  color: $yellow !important;
}

.bg-schedule {
  background-color: rgba($color: $yellow, $alpha: 0.4);
  color: $yellow !important;
}

.bg-status-3 {
  background-color: $color-red-2;
  color: $red !important;
}

.bg-save_as_draft {
  background-color: $color-red-2;
  color: $red !important;
}

.bg-status-4 {
  background-color: rgba($color: $color-blue-7, $alpha: 0.4);
  color: $color-blue-7 !important;
}

.bg-processing {
  background-color: rgba($color: $color-blue-7, $alpha: 0.4);
  color: $color-blue-7 !important;
}

.bg-failed {
  background-color: rgba($red, $alpha: 0.4);
  color: $red !important;
}

.pe-400 {
  padding-right: 400px !important;
}

.z-index-5 {
  z-index: 5;
}

.z-index-10 {
  z-index: 10;
}

.z-index-100 {
  z-index: 100;
}

.pe-65 {
  padding-right: 65px;
}

.pe-80 {
  padding-right: 80px;
}

.radius-start-0 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.radius-end-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

//Change style tab
.wrapper_tabs {
  .nav-tabs {
    .nav-link {
      border: 0;
      margin-bottom: 0;
      color: rgba($color: $color-blue, $alpha: 0.5);
      position: relative;
      padding: 1rem;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -1px;
        right: 0;
        width: 100%;
        height: 2px;
        background-color: $blue;
        opacity: 0;
      }

      &.active,
      &:hover {
        color: $blue;
        background-color: transparent;

        &:before {
          opacity: 1;
        }
      }

      &:focus {
        outline: none;
      }
    }
  }

  &.wrapper_tabs_2 {
    .nav-tabs {
      .nav-link {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        &.active,
        &:hover {
          color: $white;
          background-color: $blue;
        }
      }
    }

    .nav-tabs {
      border: 0;
    }

    .item_tab_2 {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}

.outline-none {
  &:focus {
    outline: none;
  }
}

//OverlayTrigger

.popover {
  width: 350px;
  z-index: 10;

  &:before {
    content: '';
    position: absolute;
    left: -8px;
    top: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-right: 8px solid $white;
    border-bottom: 7px solid transparent;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.main_upload_images {
  .wr_btn_dam {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    // z-index: 5;
    width: 100% !important;
    height: 100%;
  }

  .lg_btn_dam_assets {
    width: 100% !important;
    .w-50 {
      width: 100% !important;
    }
  }

  .item_dam_assets {
    border-top: 0 !important;
    margin-top: 0 !important;
  }
}

.wr_upload_images {
  &.active_img {
    .wr_icon_upload {
      opacity: 0 !important;
      position: absolute;
      z-index: -1;
    }
  }
}

.wr_video_assets {
  .wr_btn_dam {
    margin-left: 0 !important;
  }
}

.wr_dam_full_width {
  .w-50 {
    width: 100% !important;
  }
}

.icon_not_found {
  font-size: 3.5rem !important;
}

.wr_description_image_asset {
  .item_dam_assets {
    .wr_img_thumbnail_dam {
      width: 100px;
    }
  }

  .wr_img_thumbnail_canva {
    width: 100px !important;
  }
}

.react-datepicker__input-container input {
  width: 100%;
}

.btn_add_channel {
  &:focus {
    outline: none;
  }
}

.wr_wz_content_page {
  .wr_btn_back_wz {
    justify-content: space-between !important;
  }

  .btn_back_wz {
    display: block !important;
  }
}

.avatar_persona {
  .wr_btn_dam {
    z-index: 5;
  }
}

.progress {
  background-color: #d9d9d9;
  --bs-progress-height: 12px;
  --bs-progress-border-radius: 1px;
}

@include media-breakpoint-down(lg) {
  .w-400 {
    width: 250px;
  }
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: $gray-500;
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px transparent;
  border-radius: 5px;
  background-color: $gray-500;
  box-shadow: inset 0 0 6px transparent;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  background-color: #fff;
  box-shadow: inset 0 0 6px transparent;
}
.btn-outline-secondary:not(:hover) {
  background: #fff;
}
.btn-info.dropdown-toggle {
  &:focus {
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
}

///////================= Long Viet ======/////////////////
.fw-semi {
  font-weight: 600 !important;
}

.btn-light {
  @include button-variant(
    $white /*background*/,
    $gray-500 /*border*/,
    $body-color /*text color*/,
    $gray-500 /*hover background*/,
    $white /*hover border*/,
    $white /*hover text color*/
  );
  &:focus {
    outline: 1px solid $gray-500;
  }
  &.border-success {
    @include button-variant(
      $white /*background*/,
      $gray-500 /*border*/,
      $body-color /*text color*/,
      $success /*hover background*/,
      $white /*hover border*/,
      $white /*hover text color*/
    );
    .icon {
      background-color: $success;
    }
    &:hover {
      &:active {
        .icon {
          background-color: $success;
        }
      }
      .icon {
        background-color: $white;
      }
    }
  }
}
.btn-success {
  &:focus {
    outline: 1px solid $success;
  }
}

.nav-tabs {
  .nav-item {
    margin-right: 40px;
    &:last-child {
      margin-right: 0;
    }
  }
  .nav-link {
    border: 0;
    outline: 0;
    padding-left: 0;
    padding-right: 0;
    color: $gray-700;
  }
  .nav-link.active,
  .nav-item.show .nav-link {
    color: $body-color;
    background-color: transparent;
    border-bottom: 2px solid $success;
    font-weight: bold;
  }
  &.flex-column {
    border-bottom: 0;
    .nav-item {
      margin-right: 0;
      .nav-link {
        padding: 12px 0;
        border: 0;
        &.active {
          color: $success;
          font-weight: 600;
        }
      }
    }
  }
}

form {
  .form-control {
    padding: 10px 16px;
    line-height: 18px;
    font-size: 14px;
    background-color: var(--bs-white);
    &::placeholder {
      color: var(--input-placeholder-color);
    }
    &:focus {
      box-shadow: none;
      border: 1px solid $color-blue;
      background-color: var(--bs-white);
    }
    &:-webkit-autofill:focus {
      background-color: var(--bs-white) !important;
      -webkit-box-shadow: 0 0 0px 1000px var(--bs-white) inset;
    }
  }
}
.form-check {
  .form-check-input {
    width: 16px;
    height: 16px;
    margin-left: -25px;
    margin-top: 4px;
    &[type='radio'] {
      border-color: $body-color;
      &:checked {
        border-color: $success;
        background-color: $white;
      }
      &:not(:checked) {
        background-color: var(--content-bg);
      }
    }
    &[type='checkbox'] {
      border-color: $body-color;
      width: 24px;
      height: 24px;
      margin-left: -32px;
      margin-top: 0;
      &:checked {
        border-color: $success;
        background-color: $success;
      }
      &:not(:checked) {
        background-color: var(--content-bg);
      }
    }
  }
}
